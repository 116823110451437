<template src="./productCatelog.html"></template>

<script>
import productCard from '../productCard/productCard';
import header from '../header/header';

export default {
  name: 'productCatelog',
  components: {
    productCard,
    recommendationHeader: header,
  },
  data() {
    return {
      products: [],
      mainLoading: false,
    };
  },
  mounted() {
    this.getProductsByCategory();
  },
  methods: {
    getProductsByCategory() {
      this.mainLoading = true;
      this.$MS_SOCO_PUBLIC_API_URL
        .get(`/products/sociolla/category/${this.$route.params.id}`, {
          params: {
            filter: {
              store_location_id: `${this.getLocation()}`,
            },
          },
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.products = res.data.data;
            this.mainLoading = false;
          }
        })
        .catch((error) => {
          // console.log(error)
        });
    },
    getToken() {
      if (Cookies.get('token') && Cookies.get('token') != undefined) {
        return Cookies.get('token');
      }
    },
    getLocation() {
      if (Cookies.get('store_location_id') && Cookies.get('store_location_id') != undefined) {
        return Cookies.get('store_location_id');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'productCatelog';
.home-page {
  .main-section {
    padding: 50px 0px 50px;
    .user-title-main {
      letter-spacing: 2px;
      margin-top: 60px;
      margin-bottom: 48px;
      text-transform: uppercase;
    }
    .back-btn {
      margin: 0px 25px 8px;
      display: flex;
      flex-wrap: wrap;
      a {
        background: #ffe4ed;
        padding: 12px 20px;
        font-family: 'lato-medium';
        font-size: 20px;
        color: #b32656;
        line-height: 27px;
        letter-spacing: 1.67px;
        border-radius: 30px;
        margin-right: 16px;
        margin-bottom: 8px;
        text-transform: uppercase;
        display: block;
      }
    }
    h6 {
      font-family: 'brandontext-bold';
      font-size: 24px;
      letter-spacing: 1.5px;
      color: #000000;
      text-transform: uppercase;
      padding: 0px 40px;
      margin: 48px 0px 0px;
    }
    .new-store {
      display: flex;
      position: relative;
      padding: 0px;
      width: 100%;
      overflow: scroll;
    }
  }
}
@media (max-width: 900px) {
  .home-page .main-section .promotion-product {
    padding: 0px 24px 30px;
  }
  .home-page .main-section .promotion-product ul .col-md-4 {
    width: 33.33%;
    padding: 0px 10px;
  }
}
</style>
